.gallery {
    padding-bottom: 147.66px;
}

@media all and (max-width:767px) {
    .gallery {
        width: 100%;
        padding-bottom: 74px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .gallery .col {
        width: 250px;
        padding: 0px;
        margin: 0px;
    }

    .gallery img {
        width: 100%;
        height: 100%;
    }

    .gallery .col:not(:last-of-type) {
        margin-bottom: 30px;
    }
}
.map-con {
    margin-right: 262.01px;
    height: 50vh;

}

.map-con iframe {
    width: 100%;
    height: 100%;
}

.contact-details {
    padding: 0px;
    margin-top: 64.59px;
    padding-bottom: 196.05px;
}

.contact-details input,
.contact-details textarea {
    border: .55px solid var(--secondary-color);
    height: 45.15px;
    padding: 11.47px 12.42px 12.68px 12.42px;
}

.contact-details textarea {
    height: 202.94px;
    margin-bottom: 26.68px;
}



.contact-details .row-cols-1 textarea,
.contact-details .row-cols-1 input {
    width: 95%;
}

.contact-details .row-cols-2 input {
    width: 89%;
}

.contact-details input::placeholder,
.contact-details textarea::placeholder {
    font-size: 17.31px;
    color: var(--primary-color);
}

.contact-details .row:not(:last-of-type) {
    margin-bottom: 26.69px;
}

.send-con {
    width: 95%;

}

.send {
    background-color: var(--primary-color);
    padding: 10.52px 38.28px 13.63px 40.6px;
    color: var(--bg-color);
    font-size: 17.31px;
    cursor: pointer;
}

.send:hover {
    background-color: rgba(27, 62, 117, 0.562);

}


.contact-info p {
    color: var(--primary-color);
    font-size: 23.63px;
}

.contact-info p span {
    color: var(--secondary-color);
}

.contact-info p:last-of-type {
    margin-bottom: 22.32px;
}

@media all and (max-width:767px) {
    .map-con {
        margin-right: 0px;
        height: 180px;
    }

    .contact-details {
        padding-inline: 35px !important;
        margin-top: 33px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 76.17px;
    }


    .contact-details .row {
        width: 100%;
    }

    .contact-details input,
    .contact-details textarea {
        height: 32.66px;
        padding: 8.3px 0px 9.36px 8.98px;
    }

    .contact-details textarea {
        height: 100%;
        margin-bottom: 19.3px;
    }

    .contact-details input::placeholder,
    .contact-details textarea::placeholder {
        font-size: 12.52px;
    }

    .contact-details .row-cols-1 textarea,
    .contact-details .row-cols-1 input {
        width: 100%;
    }

    .contact-details .row-cols-2 input {
        width: 100%;
    }

    .send {
        padding: 7.61px 27.9px 10.05px 29.36px;
        font-size: 12.52px;
        margin-bottom: 50px;
    }

    .contact-info p {
        font-size: 15.04px
    }



    .contact-info p:last-of-type {
        margin-bottom: 22.32px;
    }



}
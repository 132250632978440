p.Categories,
a.Blog {
    font-size: 23.24px;
    color: var(--primary-color);
    margin-bottom: 22px;
}

a.Blog span {
    margin-right: 14.06px;
}

a.Blog span img {
    transform: rotateY(180deg);
    margin-bottom: 3px;
}

@media all and (max-width:767px) {

    p.Categories,
    a.Blog {
        font-size: 12px;
        margin-bottom: 2px;
        margin-right: 30px;
    }
}

.category {
    cursor: pointer;
}

.category.active {
    color: var(--primary-color)
}

.category.active a{
    color: var(--primary-color)
}
.list-of-warehouses {
    width: 85%;
    padding: 0;
    margin: 0;
    margin-top: 72.72px;
    padding-bottom: 151.04px;
}

.list-of-warehouses .col {
    margin-bottom: 74.35px;
    padding-right: 100px;
}

.list-of-warehouses .col:nth-child(4),
.list-of-warehouses .col:nth-child(5),
.list-of-warehouses .col:nth-child(6) {
    margin: 0;
}

.list-of-warehouses h2 {
    color: var(--secondary-color);
    font-size: 32.31px;
    margin-bottom: 8.66px;
}

.list-of-warehouses p {
    font-size: 19.38px;
    color: var(--primary-color);
}

@media all and (max-width:767px) {
    .list-of-warehouses {
        width: 100%;
        margin-top: 29px;
        padding-bottom: 48px;
        padding-left: 40px;
        padding-right: 20px;
    }

    .list-of-warehouses .col {
        padding-right: 30px;
    }

    .list-of-warehouses .col,
    .list-of-warehouses .col:nth-child(4) {
        margin-bottom: 29px;
    }

    .list-of-warehouses .col:nth-child(5),
    .list-of-warehouses .col:nth-child(6) {
        margin-bottom: 0px;
    }

    .list-of-warehouses .col:nth-child(2),
    .list-of-warehouses .col:nth-child(4),
    .list-of-warehouses .col:nth-child(6) {
        padding-right: 0px !important;
    }

    .list-of-warehouses h2 {
        font-size: 18px;
        margin-bottom: 6px;
    }

    .list-of-warehouses p {
        font-size: 12.07px;
    }
}
.list-of-services {
    margin: 0;
    padding: 0;
    margin-top: 72.72px;
    width: 85%;
    padding-bottom: 283.2px;
}

.list-of-services h2 {
    font-size: 32.27px;
    color: var(--secondary-color);
    margin-bottom: 28.32px;
}

.list-of-services p {
    font-size: 19.36px;
    color: var(--primary-color);
}

@media all and (max-width:767px) {
    .list-of-services {
        margin-top: 29px;
        width: 100%;
        padding-bottom: 63.55px;
        padding-inline: 19px;
    }

    .list-of-services .col:not(:last-of-type) {
        margin-bottom: 29px;
    }

    .list-of-services h2 {
        font-size: 25px;
        margin-bottom: 6px;
    }

    .list-of-services p {
        font-size: 16px;
    }
}
.partner{
    padding: 100px 20px;
    width: 100%;
    margin-inline: 0;
    max-width: 100%;
}

.partner._gray{
background-color: #f2f2f2;
}

.partner > a{
    max-width: 900px;
    margin-inline: auto;
    display: flex;
    align-items: stretch;
    justify-content: center;
    min-height: 300px;
    flex-direction: column-reverse;
}

.partner._gray > a{
    flex-direction: column-reverse;
    background-color: #fff;
}


.partner > a .image {
    width: 100%;
    transition: all .3s ease-in-out;
}
.partner > a .image img{
    width: 100%;
    object-fit: cover;
    height: 100%;
}

.partner > a .info{
    max-width: 500px;
    width: 100%;
    background-color: #f2f2f2;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;
    transition: all .3s ease-in-out;
}

.partner._gray > a .info{
        background-color: #fff;
}

.partner > a .info .info-title{
        font-size: 2rem;
        color:#1f3d74;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }

    .partner > a .info .info-title span{
            color:#82a5ce;
    }

    .partner > a .info .button{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 20px;
        position: relative;

    }

    .partner > a .info .button span{
            font-size: 1.5rem;
            color: #1f3d74;
    }

    .partner > a .info .button:after
    {
        position: relative;
        content: "";
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 10px solid #82a5ce;
    }

    .partner > a:hover .image
    {
        clip-path: polygon(0% 0%, 75% 0%, 100% 0, 100% 100%, 0% 100%);
    }

    .partner._gray > a:hover .info
    {
        background-color: #ffffff98;
    }
   .partner > a:hover .info
    {
        background-color: #f2f2f2a4;
    }


    @media only screen and (min-width: 500px) {
        .partner > a{
            flex-direction: row;
        }
        
        .partner._gray > a{
            flex-direction: row-reverse;
        }

        .partner > a .image{
            clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%);
             max-width: 400px;
        }
    }
    

.home {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
}

.home::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* animation: fade 10s infinite alternate; */
    background-size: cover;
    background-position: center;
    transition: background-image 10s linear;
    background-image: url("../../Assets/Images/Homepage-01.jpg");
}


.home p {
    position: relative;
    color: var(--bg-color);
    font-size: 49.56px;
    padding: 25px;
    z-index: 10;
    & span {
        color: var(--primary-color);
    }
}

.home p::before {
    content: url(../../Assets/Images/Home/border.svg);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}

@media all and (min-width:1540px) {
    .home p::before {
        top: -19px;
        left: -7px;
    }

    .home p {
        font-size: 49.56px;
        padding: 25px;
    }
}

@media all and (max-width:767px) {
    .home p {
        font-size: 25px;
        padding: 15px;
    }
}


@media all and (min-width:1540px) {
    
    .home p::before {
        top: -19px;
        left: -7px;
    }

    .home p {
        font-size: 49.56px;
        padding: 25px;
    }
}

@media all and (max-width:767px) {
    .home p {
        font-size: 25px;
        padding: 15px;
    }
}

@keyframes fade {
    0% {
        background-image: url("../../Assets/Images/Homepage-01.jpg");
    }

    15% {
        background-image: url("../../Assets/Images/Homepage-02.jpg");
    }

    30% {
        background-image: url("../../Assets/Images/Homepage-03.jpg");
    }

    45% {
        background-image: url("../../Assets/Images/Homepage-02.jpg");
    }

    60% {
        background-image: url("../../Assets/Images/Homepage-01.jpg");
    }

    75% {
        background-image: url("../../Assets/Images/Homepage-03.jpg");
    }

    90% {
        background-image: url("../../Assets/Images/Homepage-02.jpg");
    }

    100% {
        background-image: url("../../Assets/Images/Homepage-01.jpg");
    }
}


@keyframes hero-gradient-animation {
    0% {
        --s-start-0: 14%;
        --s-end-0: 81%;
        --y-0: 10%;
        --c-0: hsla(217.33333333333334, 62%, 28%, 1);
        --x-0: 16%;
        --x-1: 94%;
        --y-1: 10%;
        --c-1: hsla(207.27272727272728, 33%, 93%, 1);
        --s-start-1: 15%;
        --s-end-1: 50%;
        --s-start-2: 13%;
        --s-end-2: 51%;
        --c-2: hsla(332.00000000000006, 0%, 100%, 1);
        --y-2: 10%;
        --x-2: 20%;
        --c-3: hsla(214.05405405405406, 43%, 66%, 1);
        --x-3: 0%;
        --s-start-3: 3%;
        --s-end-3: 72%;
        --y-3: 80%;
    }

    100% {
        --s-start-0: 15%;
        --s-end-0: 72%;
        --y-0: 90%;
        --c-0: hsla(217.33333333333334, 62%, 28%, 0.6);
        --x-0: 26%;
        --x-1: 13%;
        --y-1: 90%;
        --c-1: hsla(205, 100%, 23%, 0.58);
        --s-start-1: 19%;
        --s-end-1: 81%;
        --s-start-2: 11%;
        --s-end-2: 58%;
        --c-2: rgb(175, 226, 255);
        --y-2: 90%;
        --x-2: 33%;
        --c-3: hsla(205, 100%, 23%, 0.58);
        --x-3: 41%;
        --s-start-3: 2%;
        --s-end-3: 65%;
        --y-3: 54%;
    }
}

@property --s-start-0 {
    syntax: '<percentage>';
    inherits: false;
    initial-value: 14%
}

@property --s-end-0 {
    syntax: '<percentage>';
    inherits: false;
    initial-value: 81%
}

@property --y-0 {
    syntax: '<percentage>';
    inherits: false;
    initial-value: 11%
}

@property --c-0 {
    syntax: '<color>';
    inherits: false;
    initial-value: hsla(217.33333333333334, 62%, 28%, 1)
}

@property --x-0 {
    syntax: '<percentage>';
    inherits: false;
    initial-value: 16%
}

@property --x-1 {
    syntax: '<percentage>';
    inherits: false;
    initial-value: 94%
}

@property --y-1 {
    syntax: '<percentage>';
    inherits: false;
    initial-value: 19%
}

@property --c-1 {
    syntax: '<color>';
    inherits: false;
    initial-value: hsla(207.27272727272728, 33%, 93%, 1)
}

@property --s-start-1 {
    syntax: '<percentage>';
    inherits: false;
    initial-value: 15%
}

@property --s-end-1 {
    syntax: '<percentage>';
    inherits: false;
    initial-value: 50%
}

@property --s-start-2 {
    syntax: '<percentage>';
    inherits: false;
    initial-value: 13%
}

@property --s-end-2 {
    syntax: '<percentage>';
    inherits: false;
    initial-value: 51%
}

@property --c-2 {
    syntax: '<color>';
    inherits: false;
    initial-value: hsla(332.00000000000006, 0%, 100%, 1)
}

@property --y-2 {
    syntax: '<percentage>';
    inherits: false;
    initial-value: 11%
}

@property --x-2 {
    syntax: '<percentage>';
    inherits: false;
    initial-value: 20%
}

@property --c-3 {
    syntax: '<color>';
    inherits: false;
    initial-value: hsla(214.05405405405406, 43%, 66%, 1)
}

@property --x-3 {
    syntax: '<percentage>';
    inherits: false;
    initial-value: 0%
}

@property --s-start-3 {
    syntax: '<percentage>';
    inherits: false;
    initial-value: 3%
}

@property --s-end-3 {
    syntax: '<percentage>';
    inherits: false;
    initial-value: 72%
}

@property --y-3 {
    syntax: '<percentage>';
    inherits: false;
    initial-value: 80%
}

.animated{
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    content: "";
    z-index: 5;
    --y-0: 11%;
    --c-0: hsla(217.33333333333334, 62%, 28%, 1);
    --x-0: 16%;
    --x-1: 94%;
    --y-1: 19%;
    --c-1: hsla(207.27272727272728, 33%, 93%, 1);
    --c-1: rgb(0, 67, 114);
    --c-2: rgb(3, 0, 0);
    --y-2: 11%;
    --x-2: 20%;
    --c-3: hsla(214.05405405405406, 43%, 66%, 1);
    --x-3: 0%;
    --y-3: 80%;
    
    background-color: transparent;
    background-image: radial-gradient(circle at var(--x-0) var(--y-0), var(--c-0) var(--s-start-0), transparent var(--s-end-0)), radial-gradient(circle at var(--x-1) var(--y-1), var(--c-1) var(--s-start-1), transparent var(--s-end-1)), radial-gradient(circle at var(--x-2) var(--y-2), var(--c-2) var(--s-start-2), transparent var(--s-end-2)), radial-gradient(circle at var(--x-3) var(--y-3), var(--c-3) var(--s-start-3), transparent var(--s-end-3));
    animation: hero-gradient-animation 5s linear infinite alternate;
    background-blend-mode: screen;
    opacity: .5; 
}
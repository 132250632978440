.footer {
    width: 100%;
    height: 304.76px;
    background-color: var(--bg-footer-color);
    padding-bottom: 10px;
}

.footer .content-footer {
    width: 125.93;
    height: 143.98;
    display: flex;
    flex-direction: column;
}

.footer .content-footer h4 {
    color: var(--secondary-color);
    font-size: 25.68px;
    line-height: 30.82px;
}

.footer .content-footer a,
.footer .content-footer p {
    color: var(--primary-color);
    font-size: 18.19px;
    line-height: 21.83px;
    padding: 7px 0px;
}

.footer .content-footer a:hover {
    color: rgba(27, 62, 117, 0.581);
    transition: .3s;
}

.footer .content-footer .icons-footer {
    padding-top: 10px;
}

.footer .content-footer .icons-footer a {
    width: 21.7px;
    height: 21.7px;
    cursor: pointer;
    border: .71px solid var(--secondary-color);

    & img {
        width: 11.7px;
        height: 11.7px;
    }
}

.footer .content-footer .icons-footer a:not(:last-of-type) {
    margin-right: 10px;
}

.footer .content-footer .input-footer {
    width: 136.99px;
    height: 21.72px;
    margin-top: 5px;
}

.footer .content-footer .input-footer input {
    width: 100%;
    height: 100%;
    border: none;
    border: 2px solid var(--primary-color);
    background-color: transparent;
    outline: none;
}

.footer .content-footer .button-footer {
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
}

.footer .content-footer .button-footer button {
    width: fit-content;
    height: 100%;
    background-color: var(--primary-color);
    color: var(--bg-color);
    font-size: 13.91px;
    line-height: 16.69px;
    border: none;
    padding: 5px 12px;

    &:hover {
        background-color: rgba(27, 62, 117, 0.7);
        transition: .3s;
    }
}

.footer .bottom-footer {
    transform: translateY(40px);
}

.footer .bottom-footer p {
    font-size: 18.19px;
    line-height: 21.83px;
    color: var(--primary-color);
}

@media all and (min-width: 1540px) {
    .footer {
        padding-inline: 283px;
    }
}

@media all and (max-width: 776px) {
    .footer {
        height: 100%;
        padding: 20px 0px;
    }

    .footer .content-footer {
        margin-bottom: 33px;
    }

    .footer .content-footer h4 {
        font-size: 20px;
        line-height: 28.8px;
    }

    .footer .content-footer a,
    .footer .content-footer p {
        font-size: 15px;
        /* line-height: 20.4px; */
        padding: 5px 0px;
    }


    .footer .content-footer .icons-footer {
        display: none;
    }

    .footer .content-footer .input-footer {
        width: 128.09px;
        height: 20.31px;
        margin-top: 5px;
    }

    .footer .content-footer .button-footer {
        margin-top: 20px;
        width: 80px;
        height: 20px;
    }

    .footer .content-footer .button-footer button {
        font-size: 13px;
        line-height: 15.6px;
        height: unset;
        border: none;
    }

    .footer .bottom-footer {
        transform: translateY(8px);
    }

    .footer .bottom-footer p {
        font-size: 15px;
        line-height: 18px;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {}
.blog-main-content {
    width: 66%;
}

.blog-main-content:not(:last-of-type) {
    margin-bottom: 118.19px;
}

.blog-main-content:last-of-type {
    margin-bottom: 79px;
}

.blog-img {
    width: 100%;
    height: 66vh;
    background-image: url(../../../Assets/Images/Blog/Asset\ 10\ 1.png);
    margin-bottom: 30px;
}


.blog-main-content .text-read-more {
    margin-bottom: 74.04px;

}

.blog-main-content .text-read-more .text {
    width: 66%;
}

.blog-main-content .text-read-more .text h2 {
    color: var(--primary-color);
    margin-bottom: 23.81px;
}

.blog-main-content .text-read-more .text p {
    color: var(--primary-color);
    font-size: 19.22px;
    line-height: 23.07px;

}

.blog-main-content .text-read-more a {
    color: var(--secondary-color) !important;
    cursor: pointer;
    font-size: 19.22px;

    & span {
        margin-left: 13.35px;
    }
}

.blog-main-content .flex-start-center p {
    color: var(--secondary-color);
    margin-right: 16px;
    font-size: 18px;
}

@media all and (min-width:1540px) {
    .blog-img {
        height: 50vh;
    }
}

@media all and (max-width:767px) {
    .blog-main-content {
        width: 100%;
    }

    .blog-main-content:last-of-type {
        margin-bottom: 60px;
    }


    .blog-main-content:not(:last-of-type) {
        margin-bottom: 60px;
    }

    .blog-img {
        width: 100%;
        height: 30vh;
        margin-bottom: 30px;
    }

    .blog-main-content .text-read-more {
        margin-bottom: 29px;
        padding-inline: 20px;

    }

    .blog-main-content .text-read-more .text h2 {
        font-size: 20px;
        margin-bottom: 15.81px;
    }

    .blog-main-content .text-read-more .text p {
        font-size: 12.22px;

    }

    .blog-main-content .text-read-more a {

        font-size: 12.22px;

        & span {
            margin-left: 6.35px;
        }
    }

    .blog-main-content .flex-start-center p {
        padding-left: 22px;
        margin-right: 16px;
        font-size: 12px;
    }



}
.qodef-mobile-header--side-area #qodef-page-mobile-header-inner .qodef-side-area-mobile-header-opener {
    margin-left: 20px;
}

.qodef-opener-icon {
    cursor: pointer;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    color: var(--primary-color);
}

.qodef-opener-icon .qodef-m-icon {
    position: relative;
    visibility: visible;
}

.qodef-opener-icon.qodef-source--predefined .qodef-svg--menu {
    display: block;
}

.qodef-svg--menu {
    fill: none;
    stroke: currentColor;
    stroke-miterlimit: 10;
}

.qodef-opener-icon.qodef-source--predefined .qodef-svg--menu line {
    stroke-dasharray: 45;
    stroke-dashoffset: 90;
    transition: stroke-dashoffset .45s ease-in-out;
}

.qodef-opener-icon.qodef-source--predefined .qodef-search-close {
    transform: rotate(0);
    transform-origin: center;
    transition: transform .3s ease-in-out
}

.qodef-opener-icon.qodef-source--predefined .qodef-svg--menu line {
    stroke-dasharray: 45;
    stroke-dashoffset: 90;
    transition: stroke-dashoffset .45s ease-in-out
}

.qodef-opener-icon.qodef-source--predefined .qodef-svg--menu line:nth-of-type(2) {
    transition-delay: .15s
}

.qodef-opener-icon.qodef-source--predefined .qodef-svg--menu line:nth-of-type(3) {
    transition-delay: .3s
}

.qodef-opener-icon.qodef-source--predefined .qodef-svg--close-menu line {
    stroke-dasharray: 22;
    stroke-dashoffset: 44;
    transition: stroke-dashoffset .44s ease-in-out
}

.qodef-opener-icon.qodef-source--predefined .qodef-svg--close-menu line:nth-of-type(2) {
    transition-delay: .22s
}

.qodef-opener-icon.qodef-source--predefined:hover .qodef-svg--close-menu line,
.qodef-opener-icon.qodef-source--predefined:hover .qodef-svg--menu line {
    stroke-dashoffset: 0
}

.qodef-opener-icon.qodef-source--predefined:hover .qodef-search-close {
    transform: rotate(90deg)
}

@media all and (max-width:767px) {
    .burger-con svg {
        width: 40.22px;
        height: 24.03px;
    }
}
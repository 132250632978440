.our-story {
    padding: 0px;
    padding-bottom: 82.26px;
    margin-top: 36.36px;
}

.our-story h3 {
    color: var(--secondary-color);
}

.our-story h2 {
    color: var(--primary-color);
    margin-bottom: 23.81px;
}


.our-story h3,
.our-story h2 {
    font-size: 32.28px;
}

.our-story p {
    color: var(--primary-color);
    width: 85%;
    font-size: 19.37px;
}

.our-story p:not(:last-of-type) {
    margin-bottom: 23.48px;
}

@media all and (max-width:767px) {
    .our-story {
        padding: 0px;
        padding-bottom: 40px;
        margin-top: 40px;
        padding-left: 19px;
        padding-right: 25px;
    }

    .our-story h3,
    .our-story h2 {
        font-size: 18px;

    }

    .our-story h3 {
        margin-bottom: 4px;
    }

    .our-story h2 {
        margin-bottom: 5px;
    }

    .our-story p {
        width: 100%;
        font-size: 12px;
    }

    .our-story p:not(:last-of-type) {
        margin-bottom: 5px;
    }

}
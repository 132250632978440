.header .logo{
    /* max-width: 250px; */
    width: 100%;
    flex-wrap: wrap;
    gap: 20px;
}
.header .logo._row{
    flex-direction: column;
    align-items: center;
    max-width: 300px;
    width: 100%;
    display: flex;
}

.header .logo img {
    margin-right: 99px;
    object-fit: contain;
    width: 100%;
}
.header .logo .text{
    width: 100%;
}

.header .logo h4 {
    font-size: 26.7518px;
    color: var(--primary-color);
}

@media all and (min-width:400px) {
    .header .logo._row{
       flex-direction: row;
       max-width: max-content;
       flex-wrap: nowrap;
    }

    .header .logo._row .text{
        max-width: 200px;
    }
    
}

@media all and (min-width:500px) {
   
    .header .logo._row .text{
        max-width: 300px;
    }
    
}

@media all and (min-width:700px) {
   
    .header .logo._row .text{
        max-width: 400px;
    }
    
}


@media all and (min-width:1540px) {
    .header .logo img {
        margin-right: 79.23px;
    }
}


@media all and (max-width:500px) {
    .header .logo._home img {
        width: 100px;
        
        margin-right: 10px;
    }

}


@media all and (min-width:500px) {
    .header .logo._home img {
        width: 100%;
        max-width: 150px;
       
    }

}


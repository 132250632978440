.systems:not(:last-of-type) {
    margin-bottom: 108.53px;
}

.systems:last-of-type {
    margin-bottom: 145.94px;
}

.ma3jon-img-1,
.ma3jon-img-2 {
    width: 73%;
    height: 100%;
    margin-right: 4.5%;
}

.ma3jon-img-1 {
    background-image: url(../../Assets/Images/OurServices/OurSystems/Asset\ 14.png);
}

.ma3jon-img-2 {
    background-image: url(../../Assets/Images/OurServices/OurSystems/Asset\ 17.png);
}

.ma3jon-logo-1,
.ma3jon-logo-2 {
    width: 113.36px;
    height: 56.68px;
}

.ma3jon-logo-1 {
    background-image: url(../../Assets/Images/OurServices/OurSystems/logo1.png);
}

.ma3jon-logo-2 {
    width: 218px;
    height: 48px;
    background-image: url(../../Assets/Images/OurServices/OurSystems/Asset\ 16.png);
}


.systems-text {
    margin-top: 37.43px;
}

.systems-text p {
    color: var(--primary-color);
    font-size: 19.25px;
    width: 74%;
}

@media all and (max-width:767px) {
    .systems:not(:last-of-type) {
        margin-bottom: 60px;
    }

    .systems:last-of-type {
        margin-bottom: 60.5px;
    }


    .ma3jon-logo-1,
    .ma3jon-logo-2 {
        width: 38.76px;
        height: 22.14px;
    }

    .ma3jon-logo-2 {
        height: 11px;
        width: 54.76px;
    }

    .ma3jon-img-1,
    .ma3jon-img-2 {
        width: 78%;
        height: 100%;
        margin-right: 4.5%;
    }

    .systems-text {
        margin-top: 30px;
        padding-inline: 19px;
    }

    .systems-text p {
        font-size: 12px;
        width: 100%;
    }
}
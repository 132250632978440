.hero {
    height: 50vh;
    padding: 0px;
}

.hero.our-history-hero {
    background-image: url(../../Assets/Images/AboutUs/OurHistory/bg.png);
}

.hero.about-us-hero {
    background-image: url(../../Assets/Images/AboutUs/AboutUs/bg.png);
}

.hero.our-crtification-hero {
    background-image: url(../../Assets/Images/AboutUs/OurCertification/bg.png);
}

.hero.our-goals-hero {
    background-image: url(../../Assets/Images/AboutUs/OurGoals/bg.png);
}

.hero.our-services-hero {
    background-image: url(../../Assets/Images/OurServices/OurServices/bg.png);
}

.hero.our-network-hero {
    background-image: url(../../Assets/Images/OurServices/OurNetwork/bg.png);
}

.hero.our-warehouse-hero {
    background-image: url(../../Assets/Images/OurServices/OurWarehouse/bg.png);
}

.hero.ma3jon {
    background-image: url(../../Assets/Images/ma3jon.png);
}

.hero.with-children {
    padding-bottom: 53.64px;
    padding-left: 53.47px;
}

.hero .text h2,
.hero .text span {
    font-weight: 300;
    font-size: 59.31px;
}

.hero .text h2 {
    color: var(--bg-color);

}

.hero .text span {
    color: var(--primary-color);
}

.hero.our-network-hero .text h2 {
    color: var(--primary-color);
}

.hero.our-network-hero .text span {
    color: var(--secondary-color);
}

@media all and (min-width:1540px) {
    .hero {
        height: 409.6px;
    }
}

@media all and (max-width:767px) {
    .hero {
        height: 180px;
    }

    .hero.ma3jon {
        height: 161px;
    }

    .hero.with-children {
        padding-bottom: 26px;
        padding-left: 19px;
    }

    .hero .text h2,
    .hero .text span {
        font-size: 30px;
        margin-bottom: 0px;
    }
}
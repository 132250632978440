.menu-header {
    position: fixed;
    top: 0;
    right: 0;
    background-color: var(--bg-color);
    height: 100vh;
    padding-top: 54px;
    padding-right: 50px;
    padding-left: 56px !important;
    z-index: 111111111;
}

.menu-header img {
    cursor: pointer;
    margin-bottom: 85px;
}

.menu-header ul {
    padding: 0px;
    width: 100%;

    list-style: none;
    font-size: 23.38px;
}

.menu-header ul li:not(:last-of-type) {
    margin-bottom: 43.4px;
}

.menu-header ul li a {
    color: var(--secondary-color);
}

@media all and (max-width:767px) {
    .menu-header {
        display: none;
    }
}
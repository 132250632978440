.our-cirtification-description {
    font-size: 19.26px;
    color: var(--primary-color);
    padding-top: 36.38px;
    padding-bottom: 75.44px;
}

@media all and (max-width:767px) {
    .our-cirtification-description {
        font-size: 14px;
        padding-top: 26px;
        padding-bottom: 52px;
        padding-inline: 19px;
    }
}
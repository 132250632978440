.sidebar {
    padding: 0;
    padding-top: 51px;
    list-style: none;
}

.sidebar li:not(:last-of-type) {
    margin-bottom: 22px;
}

.sidebar li a,
.sidebar li {
    color: var(--secondary-color);
    font-size: 23.28px;
}

.sidebar li a.active {
    color: var(--primary-color);
}

@media all and (min-width:1540px) {
    .sidebar {
        width: 170px;

    }

    .sidebar li a,
    .sidebar li {
        width: 100%;
        word-break: break-all;
        font-size: 20.5px;
    }

}

@media all and (max-width:767px) {
    .sidebar {
        padding: 14px 0px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
    }


    .sidebar li a,
    .sidebar li {
        font-size: 10px;
        margin-bottom: 0px;
    }

    .sidebar li:not(:last-of-type) {
        margin-right: 30px;
        margin-bottom: 0px;
    }
}
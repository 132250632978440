.our-network-description {
    margin: 0;
    padding: 0;
    margin-top: 37.43px;
    padding-bottom: 243.21px;
}

.our-network-description p {
    color: var(--primary-color);
    font-size: 19.25px;
    width: 70%;
}

.our-network-description p:not(:last-of-type) {
    margin-bottom: 23.47px;
}

@media all and (max-width:767px) {
    .our-network-description {
        margin: 0;
        padding: 0;
        margin-top: 22px;
        padding-bottom: 48px;
        padding-inline: 19px;
    }

    .our-network-description p {
        font-size: 13px;
        width: 100%;
    }

    .our-network-description p:not(:last-of-type) {
        margin-bottom: 5px;
    }
}
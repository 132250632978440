.time-line-con {
    padding: 0px;
    margin: 0px;
    border-left: 1px solid var(--secondary-color);
    border-top-left-radius: 2.07px;
    padding-top: 97.38px;
    padding-bottom: 138.61px;
}

.date-text-con {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
}

.date-text-con:not(:last-child) {
    margin-bottom: 62px;
}

.date-text-con::before {
    content: "";
    display: inline-block;
    height: 1.07px;
    background-color: var(--secondary-color);
    width: 25.52px;
    margin-right: 12.50px;
}

.date-text-con p {
    color: var(--primary-color);
    font-size: 23.67px;
}

.date-text-con p span {
    font-weight: 300;
    color: var(--secondary-color);
    font-size: 63.18px;
    margin-right: 33.81px;

}

@media all and (max-width:767px) {
    .small-time-line {
        padding-left: 30px;
        padding-right: 18.3px;
    }

    .time-line-con {
        padding-top: 71.23px;
        padding-bottom: 69px;
    }

    .date-text-con:not(:last-child) {
        margin-bottom: 47.58px;
    }

    .date-text-con::before {
        width: 13.52px;
        margin-right: 6.62px;
    }

    .date-text-con p span {
        font-size: 33.43px;
        margin-right: 7.56px;
    }


    .date-text-con p {
        font-size: 12.52px;
    }

}
.vision-mision {
    margin: 0px;
    padding: 0px;
    width: 85%;
    padding-bottom: 169.81px;
}

.vision-mision h2,
.vision-mision p {
    color: var(--primary-color);
}

.vision-mision h2 {
    font-size: 32.28px;
    margin-bottom: 37.77px;
}

.vision-mision p {
    font-size: 19.37px;
    width: 91%;
}

@media all and (max-width:767px) {
    .vision-mision {
        padding-left: 19px;
        padding-bottom: 65.79px;
    }

    .vision-mision .col-12:not(:last-of-type) {
        margin-bottom: 40px;
    }

    .vision-mision h2 {
        font-size: 18px;
        margin-bottom: 5px;
    }

    .vision-mision p {
        font-size: 12px;
        width: 91%;
    }
}
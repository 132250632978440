.circle{
    position: fixed;
    bottom: 20px;
    left: 20px;
    width: 60px;
    cursor: pointer;
    overflow: hidden;
    background-color: transparent;
    outline: none;
    border: none;
}
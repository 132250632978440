.small-screen-menu {
    display: none;
}

@media all and (max-width:767px) {
    .small-screen-menu {
        display: flex;
        position: absolute;
        padding-inline: 40px;
        z-index: 999999999;
        top: 0;
        width: 100%;
        background-color: var(--bg-color);
    }

    .small-screen-menu img {
        width: 35.56px;
        height: 35.56px;
    }

    .small-screen-menu ul {
        list-style: none;
        font-size: 18px;

    }

    .small-screen-menu ul li:not(:last-child) {
        margin-bottom: 15px;
    }

    .small-screen-menu ul li a {
        color: var(--secondary-color);
    }

}
@font-face {
    font-family: "Barlow";
    font-weight: 100;
    src: url(../Fonts/Barlow/Barlow-Thin.ttf);
}

@font-face {
    font-family: "Barlow";
    font-weight: 200;
    src: url(../Fonts/Barlow/Barlow-ExtraLight.ttf);
}

@font-face {
    font-family: "Barlow";
    font-weight: 300;
    src: url(../Fonts/Barlow/Barlow-Light.ttf);
}

@font-face {
    font-family: "Barlow";
    font-weight: 400;
    src: url(../Fonts/Barlow/Barlow-Regular.ttf);
}

@font-face {
    font-family: "Barlow";
    font-weight: 500;
    src: url(../Fonts/Barlow/Barlow-Medium.ttf);
}

@font-face {
    font-family: "Barlow";
    font-weight: 600;
    src: url(../Fonts/Barlow/Barlow-SemiBold.ttf);
}

@font-face {
    font-family: "Barlow";
    font-weight: 700;
    src: url(../Fonts/Barlow/Barlow-Bold.ttf);
}

@font-face {
    font-family: "Barlow";
    font-weight: 800;
    src: url(../Fonts/Barlow/Barlow-ExtraBold.ttf);
}

@font-face {
    font-family: "Barlow";
    font-weight: 900;
    src: url(../Fonts/Barlow/Barlow-Black.ttf);
}
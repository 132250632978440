.list-of-goals {
    width: 85%;
    padding: 0px;
    margin-top: 72.72px;
    padding-bottom: 170.97px;
    margin-inline: 0px;
}

.list-of-goals h2 {
    font-size: 32.26px;
    color: var(--secondary-color);
    margin-bottom: 22.57px;
}

.list-of-goals p {
    font-size: 19.35px;
    color: var(--primary-color);
}

.list-of-goals .col {
    margin-bottom: 73.25px;
}

.list-of-goals .col:last-child,
.list-of-goals .col:nth-last-child(2) {
    margin-bottom: 0px;
}

@media all and (max-width:767px) {
    .list-of-goals {
        width: 100%;
        padding-inline: 19px;
        margin-top: 29px;
        padding-bottom: 69px;
    }

    .list-of-goals h2 {
        font-size: 25px;
        margin-bottom: 5px;
    }

    .list-of-goals p {
        font-size: 14px;
    }

    .list-of-goals .col {
        margin-bottom: 29px;
    }

    .list-of-goals .col:last-child,
    .list-of-goals .col:nth-last-child(2) {
        margin-bottom: 19px;
    }

    .list-of-goals .col:last-child {
        margin-bottom: 0px;
    }

}
.about-us {
    padding: 0px;
    padding-left: 60px;
}

@media all and (min-width:1540px) {
    .about-us {
        padding-inline: 283px;
    }

    .about-us .col-md-2 {
        padding-left: 10px !important;
        width: fit-content;

    }

    .about-us .col-md-10 {
        padding-left: 50px !important;
        /* Subtracting the padding from both sides */
    }
}

@media all and (max-width:767px) {
    .about-us {
        padding-left: 0px;
    }
}
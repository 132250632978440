.header {
    padding: 54px 50px 51px 54px;
    border-bottom: 1px solid var(--secondary-color);
    z-index: 1111111;

    &.abs {
        border-bottom: 0px;
        padding-block: 0px;
        position: absolute;
        top: 54px;
    }
}

@media all and (min-width:1540px) {
    .header {
        padding-inline: 283px;
    }
}

@media all and (max-width:767px) {
    .header {
        padding: 25px 15px 29px 25px;
    }

    .header.abs {
        top: 29px;
        padding-left: 20px;
        padding-right: 33px;
    }
}
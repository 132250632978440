body {
    font-family: "Barlow";
    overflow-x: hidden;
}
html,body{
    width: 100%;
    overflow-x: hidden;
}

:root {
    --primary-color: rgba(27, 61, 117, 1);
    --secondary-color: rgba(132, 164, 206, 1);
    --bg-footer-color: #E9EFF4;
    --bg-color: white
}

.fade-left, .fade-right, .fadein, .fadeout {
    opacity: 0;
}

.fade-left._appear, .fade-right._appear, .fadein._appear, .fadeout._appear {
    transform: none;
    opacity: 1;
    transition: opacity 800ms ease-in-out, transform 800ms ease-in-out;
}

.fade-left._appear:nth-child(2n+1), 
.fade-right._appear:nth-child(2n+1), 
.fadein._appear:nth-child(2n+1), 
.fadeout._appear:nth-child(2n+1) {
    transition-delay: 40ms;
}

.fade-left {
    transform: translateX(-100%);
}

.fade-right {
    transform: translateX(100%);
}

.fadein {
    transform: translateY(50px);
}

.fadeout {
    transform: translateY(-50px);
}



.bg-full {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.flex {
    display: flex;
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.justi-between-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.justi-between-row-align-start {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.flex-row-align-end {
    display: flex;
    align-items: flex-end;
}

.flex-col-align-end {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.flex-end-start {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
}

.flex-end-center {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.flex-start-center {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 500;
}

li,
p,
input,
a {
    font-weight: 400;
}

a {
    text-decoration: none;
}

p {
    margin: 0px;
}
.partners-main-con:not(:last-of-type) {
    margin-bottom: 118.19px;
}

.partners-main-con:last-of-type {
    margin-bottom: 185.57px;
}

.partners-img {
    height: 100%;
    width: 46.5%;
    margin-right: 10.7%;
}

.partners-logo {
    width: 26%;
    height: 56.68px;
    margin-bottom: 20px;
    background-size: contain !important;
}

.flex-partners {
    margin-top: 37.43px;
}

.flex-partners:not(:last-of-type) {
    margin-bottom: 118.19px;
}

.flex-partners p {
    color: var(--primary-color);
    font-size: 19.22px;
    line-height: 23.07px;
    width: 47.5%;
    margin-right: 10%;
}

.flex-partners a {
    font-size: 19.22px;
    color: var(--secondary-color) !important;
}

.flex-partners a span {
    margin-left: 14.06px;
}


@media all and (max-width:767px) {
    .partners-main-con:not(:last-of-type) {
        margin-bottom: 60px;
    }

    .partners-main-con:last-of-type {
        margin-bottom: 78px;
    }

    .partners-img {
        width: 70.5%;
        margin-right: 5.7%;
    }

    .partners-logo {
        width: 39.36px;
        height: 19.68px;
        margin-bottom: 10px;
    }

    .flex-partners {
        margin-top: 28px;
        flex-direction: column;
        padding-left: 14px;
        padding-right: 47px;
    }

    .flex-partners:not(:last-of-type) {
        margin-bottom: 60px;
    }

    .flex-partners p {
        font-size: 14px;
        width: 100%;
        margin-right: 0%;
        margin-bottom: 15.05px;
    }

    .flex-partners a {
        font-size: 16px;
    }

    .flex-partners a span {
        margin-left: 9px;
    }


}
.contact-info .social,
.blog-main-content .social {
    border: .71px solid var(--secondary-color);
    width: 45.15px;
    height: 45.15px;
}

.contact-info .social:not(:last-of-type) {
    margin-right: 17.51px;
}

.blog-main-content .social:not(:last-of-type) {
    margin-right: 17px;
}

@media all and (max-width:767px) {

    .contact-info .social,
    .blog-main-content .social {
        width: 25.15px;
        height: 25.15px;
    }

    .contact-info .social img,
    .blog-main-content .social img {
        width: 50%;
        height: 50%;
    }
}